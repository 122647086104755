import Card from "./components/Card";
import Card2 from "./components/Card2";
import Card3 from "./components/Card3"
function App() {
  return <div>
    <div className="test">
    <div className="container-card">
    <Card/>
    <Card/>
    <Card/>
    </div>
   <div className="container2">
    <Card2/>
    <Card2/>
    <Card2/>
  </div>
  <div>
    <Card3/>
    <Card3/>
    <Card3/>
  </div> 
  </div> 
  </div>
}

export default App;
